import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
    return (
        <div className="footer">
            <ul className="social-platform">
                <FaLinkedin className="linkedin"/>
                <FaGithub className="github"/>
                <FaFacebook className="facebook"/>
                <FaInstagram className="instagram"/>
            </ul>
        </div>
    );
}

export default Footer;