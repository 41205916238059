import { MdLightbulbCircle } from "react-icons/md";
import { MdOutlineTroubleshoot } from "react-icons/md";
import { FaLaptopCode } from "react-icons/fa";
import profilePic from "../../assets/profile-pic.jpeg";

const Home = () => {
    return (
        <>
            <div className="container-left">
                <img className="profile-pic" src={profilePic} />
            </div>

            <div className="container-right">
                <div className="introduction">
                    <hr/>
                    <h1>Sanjeev <br/> Budha, a <span>web developer</span></h1>
                    <hr/>

                    <div className="description">
                        <span><MdLightbulbCircle className="point-one"/> &nbsp; Experienced, Innovative and Motivated</span><br/>
                        <span><FaLaptopCode className="point-two"/> &nbsp; Capable of translating user requirements into features</span><br/>
                        <span><MdOutlineTroubleshoot className="point-three"/> &nbsp; Troubleshooting issues and coming up with solutions</span>
                    </div>
                </div>

                <div className="buttons">
                    <a className="contact-me" href="#contact-me">contact me</a>
                </div>
            </div>
        </>
    );
}

export default Home;