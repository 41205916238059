import { BiSolidCoffee } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Header.css";

const Header = () => {
    return (
        <div className="header">
            <div className="header-left">
                <BiSolidCoffee className="coffee-icon"/>
                <a href="#default" className="site-title">my<span>portfolio.</span></a>
            </div>

            <div className="header-middle">
                <a className="active" href="#home">home</a>
                <a href="#about">work experiences</a>
                <a href="#projects">projects</a>
                <a href="#articles">articles</a>
            </div>

            <div className="header-right">
                <a className="resume" href="#resume"> 
                    resume <FaDownload className="download-icon"/>
                </a>

                <GiHamburgerMenu className="hamburger-icon"/>
            </div>
        </div>
    );
}

export default Header;