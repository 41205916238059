import Header from './components/header/Header';
import Container from './components/container/Container';
import Footer from './components/footer/Footer';
import "./App.css"

function App() {
  return (
      <>
        <Header/>

        <Container/>

        <Footer/>
      </>
  );
}

export default App;
