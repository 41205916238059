import Home from "./Home";
import WorkExperiences from "./WorkExperiences";
import "./Container.css";

const Container = () => {
    return(
        <div className="container">
            <Home/>

            <WorkExperiences/>
        </div>
    );
}

export default Container;